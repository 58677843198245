<template>
  <!-- 编码:lzq -->
  <div>
    <main>
      <!-- 头部横幅 -->
      <div class="banner">
        <div class="banner1">
          <img
            v-lazy="require('../assets/imgs/cultural_relic/banner_1.webp')"
            alt=""
          />
        </div>
        <!-- 横幅内容区域 -->
        <div class="banner2">
          <h2 class="title">AR扫码文物卡片</h2>
          <p class="introduce">
            通过故事化、情境化、立体化的呈现，利用现代信息技术（如VR、AR等）
            和多媒体等展现形式，揭示出文物本身蕴含的历史文化价值
          </p>
        </div>
      </div>
      <p class="title_h2">视频展示</p>
      <span class="introduce_span">
        扫描海报、包装、艺术作品等平面媒体，展现视频短片、让静态媒体“动”起来
      </span>
      <!-- 视频展示区域 -->
      <div class="videos">
        <div class="video_1">
          <img
            v-lazy="require('../assets/imgs/cultural_relic/gand_up16.webp')"
          />
        </div>
        <div class="video_2">
          <div class="video_1_item">
            <img class="itemimg"
              v-lazy="require('../assets/imgs/cultural_relic/gand_up5.webp')"
            />
          </div>
          <div class="video_2_item">
            <img class="itemimg"
              v-lazy="require('../assets/imgs/cultural_relic/gand_up4.webp')"
            />
          </div>
        </div>
        <div class="flexs">
          <div class="video_3">
            <div class="video3_img">
              <img
                v-lazy="require('../assets/imgs/cultural_relic/gand_up2.webp')"
              />
            </div>
            <div class="control">
              <img v-lazy="require('../assets/imgs/cultural_relic/stop.png')" />
            </div>
          </div>
          <div class="video_3">
            <div class="video3_img">
              <img
                v-lazy="require('../assets/imgs/cultural_relic/gand_up2_1.webp')"
              />
            </div>
            <div class="control">
              <img v-lazy="require('../assets/imgs/cultural_relic/stop.png')" />
            </div>
          </div>
          <div class="video_3">
            <div class="video3_img">
              <img
                v-lazy="
                  require('../assets/imgs/cultural_relic/gand_up_2_2.webp')
                "
              />
            </div>
            <div class="control">
              <img v-lazy="require('../assets/imgs/cultural_relic/stop.png')" />
            </div>
          </div>
        </div>
      </div>
      <!-- 3D模型展示（苹果及安卓手机都支持） -->
      <div class="model_show">
        <p>3D模型展示（苹果及安卓手机都支持）</p>
        <span
          >实现720°产品展示，还可虚拟拜访在家里或者办公室，随时随地可欣赏</span
        >
        <div class="show_items">
          <div class="D_show">
            <img
              v-lazy="require('../assets/imgs/cultural_relic/gand_up10.webp')"
            />
          </div>
          <div class="D_show">
            <img
              v-lazy="require('../assets/imgs/cultural_relic/gand_up11.webp')"
            />
          </div>
          <div class="D_show">
            <img
              v-lazy="require('../assets/imgs/cultural_relic/gand_up12.webp')"
            />
          </div>
        </div>
      </div>
      <!-- 真人讲解 -->
      <div class="model_show explain">
        <p>真人讲解</p>
        <span>让真人来到您的面前给您讲解，一种真实感、亲切感油然而生</span>
        <div class="show_items">
          <div class="D_show">
            <img
              v-lazy="require('../assets/imgs/cultural_relic/gand_up13.webp')"
            />
          </div>
          <div class="D_show">
            <img
              v-lazy="require('../assets/imgs/cultural_relic/gand_up14.webp')"
            />
          </div>
          <div class="D_show">
            <img
              v-lazy="require('../assets/imgs/cultural_relic/gand_up15.webp')"
            />
          </div>
        </div>
      </div>
    </main>
    <!-- 头部组件 -->
    <Header></Header>
    <!-- 底部组件 -->
    <Footer></Footer>
  </div>
</template>
<script>
// @ is an alias to /src
//引入头部组件
import Header from "@/components/Header.vue";
// 引入底部组件
import Footer from "@/components/Footer.vue";
export default {
  name: "cultural_relic",
  components: {
    Header,
    Footer,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
* {
  box-sizing: border-box;
}
.banner {
  height: 600px;
  color: #fff;
  position: relative;
  .banner1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
    }
  }
  .banner2 {
    .title {
      height: 67px;
      font-size: 48px;
      font-weight: 500;
      line-height: 67px;
      letter-spacing: 3px;
      text-align: left;
      position: absolute;
      left: 361px;
      top: 230px;
    }
    .introduce {
      width: 790px;
      font-size: 24px;
      font-weight: 400;
      text-align: left;
      position: absolute;
      left: 361px;
      top: 321px;
    }
  }
}
.introduce,
.title {
  text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  animation: sport 0.5s;
  transform: translateY(0px);
}
//设置头部文字动画效果
@keyframes sport {
  0% {
    transform: translateY(80px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

//版心
.title_h2 {
  font-size: 40px;
  font-weight: 500px;
  color: #333;
  letter-spacing: 1px;
  margin-bottom: 24px;
  margin-top: 80px;
}
.introduce_span {
  font-size: 20px;
  font-weight: 400px;
  color: #5f5f5f;
  letter-spacing: 1px;
}
.videos {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  margin-top: 60px;
  margin-bottom: 100px;
  width: 1200px;
  height: 725px;

  .video_1 {
    width: 687px;
    height: 459px;
    border-radius: 10px;
    margin: 0 30px 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // loading大小
    img[lazy="loading"] {
      width: 200px;
    }
    // 实际图片大小
    img[lazy="loaded"] {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
  .video_2 {
    width: 483px;
    height: 485px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .video_1_item,
    .video_2_item {
      // .item.img{
      //    width:25.104vw;
      // height:11.906vw;
      // }
      width:25.104vw;
      height:11.906vw;
      border-radius:0.521vw;
      margin-bottom:1.563vw;
      display: flex;
      justify-content: center;
      align-items: center;
      // loading大小
      img[lazy="loading"] {
        width: 150px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
  }
}
.model_show {
  height: 886px;
  background: #f6f8fb;
  padding-top: 80px;
  p {
    font-size: 40px;
    font-weight: 500;
    color: #333333;
    letter-spacing: 1px;
    margin-bottom: 24px;
  }
  span {
    display: block;
    margin-bottom: 66px;
    font-size: 20px;
    font-weight: 400;
    color: #565656;
    letter-spacing: 1px;
  }
  .show_items {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: auto;
    // .show_2 {
    //   margin: 0 39px 0 39px;
    // }
    .D_show {
      width: 370px;
      height: 518px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 10px solid #333;
      border-radius: 10px;
      background: #333;
      // loading大小
      img[lazy="loading"] {
        width: 150px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        vertical-align: top;
      }
    }
  }
}
.explain {
  background: #fff;
}
.flexs {
  width: 1200px;
  display: flex;
  justify-content: space-between;
  .video_3 {
    position: relative;
    width: 380px;
    height: 236px;
    display: flex;
    justify-content: center;
    border-radius: 10px;
    background: #000;
    .video3_img {
      width: 358px;
      height: 215px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      margin-top: 11px;
      // loading大小
      img[lazy="loading"] {
        width: 100px;
      }
      // 实际图片大小
      img[lazy="loaded"] {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    }
    .control {
      position: absolute;
      top: 96px;
      left: 168px;
      img {
        width: 44px;
        height: 44px;
      }
    }
  }
}
</style>
